const createWorkDetails = (artist, series, title, year, type, dimension, imgCount) => {
  const basePath = require.context('@/assets/work/', true, /\.(jpg|jpeg|png|gif)$/);
  const workDetails = [];

  for (let i = 1; i <= imgCount; i++) {
    try {
      const src = basePath(`./${artist}/${series}/${i}.jpg`);
      workDetails.push({
        src: src,
        description: i === 1 ? `${title}, ${year}, ${type}, ${dimension}` : 'Description of Image'
      });
    } catch (e) {
      console.warn(`Image not found: ${artist}/${series}/${i}.jpg`);
    }
  }
  return workDetails;
};

const artistWorkDetail = {
  TaejunYun: {
    Weight_of_Remorseful: createWorkDetails('YTJ', 1, '회한의 무게 Weight of Remorseful', '2013-2014', 'Pigment print', '101.6x127cm', 10),
    Ascent: createWorkDetails('YTJ', 2, '상승 Ascent', '2020', 'Pigment print, Swiss Embassy Commision Work', 'Dimension variable', 12),
    Illusion_Stair: createWorkDetails('YTJ', 3, '환상계단 Illusion stair', '2018', 'Pigment print, Buk-Seoul modern museum Commision Work', 'Dimension variable', 10),
    Middle_Turn: createWorkDetails('YTJ', 4, '미들턴 Middle turn', '2020-2022', 'Pigment print', 'Dimension Variable', 19),
    Network: createWorkDetails('YTJ', 5, '네트워크 Network', '2022~', 'Pigment print', 'Dimension Variable', 29)
  },
  YoungjinYoo: {
    Nowhere: createWorkDetails('YYJ', 1, 'Nowhere', 'Unknown', 'Pigment print', 'Dimension variable', 5),
    In_A_Twinkle: createWorkDetails('YYJ', 2, 'In A Twinkle', 'Unknown', 'Pigment print', 'Dimension variable', 7),
    The_Weathering: createWorkDetails('YYJ', 3, 'The Weathering', 'Unknown', 'Pigment print', 'Dimension variable', 6)
  },
  YoungdonJung: {
    Ants: createWorkDetails('JYD', 1, '개미 Ants', '2013-2016', 'Pigment print', 'Dimension variable', 13),
    Wondering_Wandering: createWorkDetails('JYD', 2, '의아한 산책 Wondering Wandering', '2011-2015', 'Pigment print', 'Dimension variable', 18),
    Inner_Color: createWorkDetails('JYD', 3, 'Inner Color', '2014', 'Woodboards, Iron frame', '600x300cm, Pigment print, Dimension variable', 9)
  },
  ChankyuKim: {
    A_Dull_Complete_Work: createWorkDetails('KCK', 1, '시시한전집 A Dull Complete Works', '2014', 'C-print', 'Dimension variable', 19),
    New_shinshin_Photo_Studio: createWorkDetails('KCK', 2, '신신사진관 New Sinsin Photo Studio', '2014', 'Photo installation', 'Dimension variable', 7),
    Story_Story_and_Story: createWorkDetails('KCK', 3, '이야기, 이야기 그리고 이야기 Story, Story and Story', '2015', 'Photo installation', 'Dimension variable', 14)
  },
  HyungsikKim: {
    Studio_Practice_1: createWorkDetails('KHS', 1, 'Studio Practice 1', '2013', 'C-Print', '150x120cm', 10),
    Studio_Practice_2: createWorkDetails('KHS', 2, 'Studio Practice 2', '2013', 'C-Print', '150x120cm', 7),
    Seascape: createWorkDetails('KHS', 3, 'Seascape', '2014', 'C-Print', 'Dimension variable', 11),
    White_Object: createWorkDetails('KHS', 4, 'White Object', '2017', 'Archival Pigment Print', '150x120 cm', 9),
    Two_Squares_and_One_Circle_and_One_Triangle: createWorkDetails('KHS', 5, 'Two Squares and One Circle and One Triangle', 'Unknown', 'Pigment print', 'Dimension variable', 3)
  },
  JaeyeonKim: {
    '4810_Days': createWorkDetails('KJY', 1, '4810 Days', '2012', 'Pigment print', 'Dimension variable', 10),
    Untitled: createWorkDetails('KJY', 2, '무제 Untitled', '2014', 'Pigment print', 'Dimension variable', 7),
    A_Portrait: createWorkDetails('KJY', 3, 'A Portrait', '2015-2016', 'Pigment print', '80x100cm', 8),
    Green_Time: createWorkDetails('KJY', 4, 'Green Time', '2016', 'Pigment print', '40x50cm', 5),
    The_tree_I_live: createWorkDetails('KJY', 5, 'The tree I live', '2017', 'Pigment print', '100x125cm', 8),
    '0g_Drawing': createWorkDetails('KJY', 6, '0g Drawing', '2016-2017', 'Pigment print', '64x80cm', 8)
  },
  JungjinAhn: {
    Perspective_of_Viewpoint: createWorkDetails('AJJ', 1, '시각의 관점 Perspective of Viewpoint', '2012', 'Pigment print', '100cmx80cm', 8),
    No_Way_out: createWorkDetails('AJJ', 2, '출구없음 No Way Out', '2015', 'Pigment print', '100cmx80cm', 7),
    Forest_of_Souvenir: createWorkDetails('AJJ', 3, '기억의 숲 Forest of Souvenir', '2017', 'Pigment print', 'Variable size', 8)
  },
  JihyunJung: {
    Demolition_Site: createWorkDetails('JJH', 1, 'Demolition Site', '2013', 'Pigment print', '120x160cm', 20),
    Construction_Site_Dreg: createWorkDetails('JJH', 2, 'Construction Site Dreg', '2012', 'Pigment print', '90x75cm', 10),
    Red_Room: createWorkDetails('JJH', 3, 'Red Room', '2015', 'Pigment print on adhesive vinyl', '667X1812cm', 8),
    The_Darkside: createWorkDetails('JJH', 4, 'The Darkside', 'work in progress', 'Pigment print', '125x155cm', 4)
  },
  TaejoongKim: {
    Foresta: createWorkDetails('KTJ', 1, 'Foresta', '2013', 'Pigment print', '102x148cm', 7),
    Foresta_reverse: createWorkDetails('KTJ', 2, 'Foresta-reverse', '2014', 'Pigment print', '88x148cm', 12)
  },
  EunjuChoi: {
    New_Family_Portrait: createWorkDetails('CEJ', 1, 'New-Family Portrait', '2019', 'Pigment print', '80x100cm/56x70cm', 10),
    New_Korean_Family: createWorkDetails('CEJ', 2, '新 한국의 가족 New Korean Family', '2020', 'Pigment print', 'Dimension variable', 8)
  },
  ChanminJung: {
    The_front_image_of_the_wall: createWorkDetails('JCM', 1, 'The front image of the wall', '2017', 'Pigment print', '70x50cm', 5),
    The_volume_of_other_side: createWorkDetails('JCM', 2, '이면의 부피 The volume of other side', '2019~2020', 'Pigment print', '105x70cm', 4)
  },
  YounghoJung: {
    Monologue_Protest: createWorkDetails('JYH', 1, 'Monologue Protest', '2017-2018', 'Pigment print', 'Dimension Variable', 8),
    Lightless_Photography: createWorkDetails('JYH', 2, 'Lightless Photography', '2019-2020', 'Pigment print', 'Dimension Variable', 5),
    Unphotographable_Cases: createWorkDetails('JYH', 3, 'Unphotographable Cases', '2020-2021', 'Pigment print', 'Dimension Variable', 6),
    Face_Shopping: createWorkDetails('JYH', 4, 'Face Shopping', '2020-2021', 'Pigment print', 'Dimension Variable', 5)
  },
  SeungwonJung: {
    Bark: createWorkDetails('JSW', 1, 'Bark', '2018', 'UV print on fabric, paper', '28x28cm', 17),
    Memories_Full_of_Forgetting: createWorkDetails('JSW', 2, 'Memories Full of Forgetting', '2017-', 'UV print on fabric, paper, magnet, rock', 'Dimensions variable', 11)
  },
  CristianDoeller: {
    Replay_pyramid_splash: createWorkDetails('CRD', 1, 'Replay (pyramid splash)', '2017 / 2020', 'Pigment Print', '50 x 75 cm', 6),
    Luminograf_1: createWorkDetails('CRD', 2, '730331879 (detail)', '2010/2022', 'Pigment Print mounted on aluminium', '201 x 134 cm', 6),
    '730331879_detail': createWorkDetails('CRD', 3, '730331879 (detail)', '2010/2022', 'Pigment Print mounted on aluminium', '201 x 134 cm', 2)
  },
  HyeonwooLee: {
    '37°24\'03.3"N 126°39\'19.0"E': createWorkDetails('LHW', 1, '37°24\'03.3"N 126°39\'19.0"E', '2018', 'Pigment print', 'Dimension variable', 4),
    Overlayer: createWorkDetails('LHW', 2, 'Overlayer', '2020', 'Pigment print', 'Dimension variable', 9),
    Soft_echo: createWorkDetails('LHW', 3, 'Soft echo', '2021', 'Pigment print', 'Dimension variable', 9)
  },
  YeeunLee: {
    turn_up_the_room_temperature: createWorkDetails('LYE', 1, '실내 온도 높이기 turn up the room temperature', '2021', 'Pigment print', '60x84cm', 10),
    Mind_Building: createWorkDetails('LYE', 2, '마음쌓기05 Mind Building05', '2020', 'Pigment print', '130x90cm', 6),
    Space_Building: createWorkDetails('LYE', 3, '공간쌓기01 Space Building01', '2021', 'Pigment print', '60x40cm', 10)
  },
  DoyoungKim: {
    On_the_Ground_Underground: createWorkDetails('KDY', 1, 'On the Ground_ Underground', '2021', 'Pigment print', 'Dimension variable', 9),
    Flat_Room: createWorkDetails('KDY', 2, 'Flat Room', '2022', 'Pigment print and installation', 'Dimension variable', 8),
    '80g': createWorkDetails('KDY', 3, '80g', '2022', 'Pigment print', 'Dimension variable', 10)
  }
};

export default artistWorkDetail;
