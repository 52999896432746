import { createApp } from 'vue'; 
import App from './App.vue';
import router from './router';
import './assets/global.css';
import VueLazyLoad from 'vue-lazyload';
import EasyLightbox from 'vue-easy-lightbox';
import VueGtag from 'vue-gtag-next'; // 추가

const app = createApp(App);

// VueLazyLoad 사용
app.use(VueLazyLoad);

// VueEasyLightbox 전역 컴포넌트 등록
app.component('EasyLightbox', EasyLightbox);

// vue-gtag-next 설정
app.use(VueGtag, {
  property: {
    id: process.env.VUE_APP_GA_MEASUREMENT_ID // 환경 변수 사용
  },
  isEnabled: process.env.NODE_ENV === 'production'
}, router); // 라우터를 전달하여 자동 페이지뷰 추적 활성화

app.use(router);

app.mount('#app');